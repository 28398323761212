import React from "react";
import TWEEN from "tween.js";
import Exterior from "../Exterior";
import Interior from "../Interior";
import OffRoad from "../OffRoad";
import NavBar from "../NavBar";
import Config from "../../services/Config";
import "./app.scss";
import { CSSTransition } from "react-transition-group";
import axios from "axios";
import { loadingProgress } from "../../services/LoadingProgress";
import { sendGoogleData } from "../../services/ga";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.config = new Config();
    this._onResize = this.onResize.bind(this);
    this.containerRef = React.createRef();
    this.videoRef = React.createRef();
    this.state = {
      fullScreenEnabled: false,
      isMobile: false,
      isTouch: false,
      containerWidth: 0,
      containerHeight: 0,
      configIsLoading: true,
      isLoading: true,
      isVideoEnded: false,
      loaderVideoSrc: "", // this.config.config.loader.videoUrl,
      loaderCanPlayVideo: false,
      menuItems: [
        { id: "exterior", active: true, title: "ЭКСТЕРЬЕР" }, // Must true
        { id: "interior", active: false, title: "ИНТЕРЬЕР" },
      ],
      isOffRoadRender: false,
    };
    this.animationFrameId = null;
  }

  isActiveMenu(id) {
    const menuItem = this.state.menuItems.find((item) => item.id === id);
    return menuItem && menuItem.active;
  }

  checkSpeed = () =>
    new Promise((resolve) => {
      const userImageLink =
        "https://vtd-static.skoda-avto.ru/auto24/TestDrive/Skoda_Enyaq.jpg";
      let time_start, end_time;

      // The size in bytes
      const downloadSize = 1579199;
      const downloadImgSrc = new Image();

      time_start = new Date().getTime();
      downloadImgSrc.width = 360;
      downloadImgSrc.height = 200;
      downloadImgSrc.src = userImageLink + "?i=" + time_start;

      downloadImgSrc.onload = () => {
        end_time = new Date().getTime();
        const timeDuration = (end_time - time_start) / 1000;
        const loadedBits = downloadSize * 8;

        /* Converts a number into string
          using toFixed(2) rounding to 2 */
        const bps = (loadedBits / timeDuration).toFixed(2);
        const speedInKbps = (bps / 1024).toFixed(2);
        const speedInMbps = (speedInKbps / 1024).toFixed(2);
        console.log("speeder", speedInMbps);
        resolve(speedInMbps);
      };
    });

  setSpeed = async (imageCount) => {
    let cspeedArr = [];
    for (let i = 0; i < imageCount; i++) {
      cspeedArr.push(await this.checkSpeed());
    }
    const cspeed = Math.max(...cspeedArr);
    // const cspeed = cspeedArr.reduce((a, b) => (a + b)/cspeedArr.length, 0)

    console.log(cspeed);

    this.setState({ cspeed: cspeed });
  };

  renderOffRoad = (isOffRoadRender) => {
    this.setState({ isOffRoadRender });
  };

  iosZoomHack = () => {
    document.addEventListener(
      "touchmove",
      function (event) {
        const agent = window.navigator.userAgent,
          start = agent.indexOf("OS ");

        if (
          (agent.indexOf("iPhone") > -1 || agent.indexOf("iPad") > -1) &&
          start > -1
        ) {
          // if (AFRAME.utils.device.isIOS()) {
          // console.log("kek")
          // DeviceMotionEvent.requestPermission().then(response => {
          //   if (response == 'granted') {
          //       console.log("accelerometer permission granted");
          //       // Do stuff here
          //   }
          // });

          if (event.scale !== 1) {
            event.preventDefault();
          }
        }
      },
      { passive: false }
    );
  };

  render() {
    //this.bumpIsMobile();
    //if (this.state.configIsLoading) return null;
    const content = (
      <React.Fragment>
        {!this.isActiveMenu("offroad") && (
          <NavBar
            onChangeMenu={this.onChangeMenu.bind(this)}
            items={this.state.menuItems}
          />
        )}

        {/* {!this.isActiveMenu('offroad') && ( */}
        <div
          className={this.getFullScreenButtonClasses()}
          onClick={this.setFullScreen.bind(this, undefined)}
        />
        {/* )} */}

        {this.isActiveMenu("offroad") && this.state.loaderCanPlayVideo && (
          <OffRoad
            isMobile={this.state.isMobile}
            isTouch={this.state.isTouch}
            config={this.config.config.offroad}
            loaderUrl={this.state.loaderVideoSrc}
            cspeed={this.state.cspeed}
            onlyvirtualtestdrive={this.state.onlyvirtualtestdrive}
            modelName={this.config.config.name}
            onStartLoading={() => {
              this.setFullScreen(!this.firstOffroad); // Must true
              this.startLoading();
            }}
            onStopLoading={this.stopLoading.bind(this)}
            onCloseOffroad={this.closeOffRoad.bind(this)}
            isOffRoadRender={this.state.isOffRoadRender}
            renderOffRoad={this.renderOffRoad.bind(this)}
          />
        )}
        {this.isActiveMenu("exterior") && this.state.loaderCanPlayVideo && (
          <Exterior
            isMobile={this.state.isMobile}
            isTouch={this.state.isTouch}
            containerWidth={this.state.containerWidth}
            containerHeight={this.state.containerHeight}
            config={this.config.config.exterior}
            spotConfig={this.config.config.spot}
            onStartLoading={this.startLoading.bind(this)}
            onStopLoading={this.stopLoading.bind(this)}
          />
        )}
        {this.isActiveMenu("interior") && this.state.loaderCanPlayVideo && (
          <Interior
            isMobile={this.state.isMobile}
            isTouch={this.state.isTouch}
            containerWidth={this.state.containerWidth}
            containerHeight={this.state.containerHeight}
            config={this.config.config.interior}
            spotConfig={this.config.config.spot}
            onStartLoading={this.startLoading.bind(this)}
            onStopLoading={this.stopLoading.bind(this)}
          />
        )}
      </React.Fragment>
    );

    return (
      <div className="sk-app" ref={this.containerRef}>
        <>
          {!this.state.configIsLoading && content}
          {this.state.isLoading && (
            <CSSTransition
              in={this.state.isLoading || !this.state.isVideoEnded}
              classNames="sk-app__loader"
              unmountOnExit
              timeout={500}
            >
              <div className="sk-app__loader">
                {this.state.loaderCanPlayVideo && (
                  <video
                    className="sk-app__loader-video"
                    src={this.state.loaderVideoSrc}
                    muted={true}
                    autoPlay={true}
                    playsInline={true}
                    //loop={true}
                    ref={this.videoRef}
                    onEnded={this.onVideoEnded.bind(this)}
                  />
                )}
                {
                  <>
                    <div
                      className={`sk-app__loader-spinner ${
                        this.state.loaderCanPlayVideo &&
                        "sk-app__loader-spinner_bottom"
                      }`}
                    >
                      <div className="sk-app__loader-spinner-wrapper">
                        <div
                          className="sk-app__loader-spin"
                          data-uk-spinner="ratio: 2"
                        />
                        <span className="sk-app__loader-percent">
                          {loadingProgress.getProgress()}%
                        </span>
                      </div>
                      <span className="sk-app__loader-label">Загрузка</span>
                    </div>
                  </>
                }
              </div>
            </CSSTransition>
          )}
        </>
      </div>
    );
  }

  closeOffRoad() {
    this.setFullScreen(false);
    this.firstOffroad = false;
    this.onChangeMenu(1);
  }

  onChangeMenu(menuIndex) {
    this.setState((state) => ({
      menuItems: state.menuItems.map((item, index) =>
        index === menuIndex
          ? { ...item, active: true }
          : {
              ...item,
              active: false,
            }
      ),
    }));
  }

  getFullScreenButtonClasses() {
    const classes = ["sk-fullscreen-button"];
    if (this.state.fullScreenEnabled) {
      classes.push("sk-fullscreen-button--entered");
    }
    if (!this.canSetFullScreen) {
      classes.push("sk-fullscreen-button--hide");
    }
    return classes.join(" ");
  }

  get canSetFullScreen() {
    return (
      !!(
        window.document.body.requestFullscreen ||
        window.document.webkitRequestFullScreen ||
        window.document.mozRequestFullScreen ||
        window.document.msRequestFullscreen
      ) &&
      !!(
        window.document.exitFullscreen ||
        window.document.webkitExitFullscreen ||
        window.document.mozCancelFullScreen
      )
    );
  }

  setFullScreen(forceValue) {
    const value =
      typeof forceValue !== "undefined"
        ? !forceValue
        : this.state.fullScreenEnabled;
    const container = this.containerRef.current;
    const document = window.document;
    const enableFullScreen =
      container.requestFullscreen ||
      container.webkitRequestFullScreen ||
      container.mozRequestFullScreen ||
      container.msRequestFullscreen;
    const disableFullScreen =
      document.exitFullscreen ||
      document.webkitExitFullscreen ||
      document.mozCancelFullScreen;
    const isInFullScreen =
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement;
    if (!enableFullScreen || !disableFullScreen) {
      return;
    }
    !value
      ? enableFullScreen.call(container)
      : isInFullScreen && disableFullScreen.call(document);
  }

  onFullScreenChange(event) {
    event.preventDefault();
    this.setState({
      fullScreenEnabled: !!(
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
      ),
    });
  }

  bumpIsMobile() {
    const container = this.containerRef.current;
    const isMobile = container && container.clientWidth < 600;
    const isTouch = "ontouchstart" in document.documentElement;
    this.setState({
      isMobile,
      isTouch,
      containerWidth: container && container.clientWidth,
      containerHeight: container && container.clientHeight,
    });
  }

  onResize() {
    this.bumpIsMobile();
  }

  animate(time) {
    this.animationFrameId = window.requestAnimationFrame(
      this.animate.bind(this)
    );
    TWEEN.update(time);
  }

  onVideoEnded() {
    this.setState({ isVideoEnded: true });
    this.videoRef.current.play();
  }

  startLoading() {
    this.setState({ isLoading: true });
  }

  stopLoading() {
    //this.setState({ isLoading: false, loaderCanPlayVideo: false });
    this.setState({ isLoading: false });
  }

  getPercentageScale() {
    if (this.isActiveMenu("exterior")) {
      return 10;
    }

    if (this.isActiveMenu("offroad")) {
      return 100;
    }

    if (this.isActiveMenu("interior")) {
      return 50;
    }

    return 100;
  }

  preloadVideo() {
    const onDownloadProgress = (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * this.getPercentageScale()) / progressEvent.total
      );
      loadingProgress.setProgress(percentCompleted);
    };

    loadingProgress.clearProgress();

    return axios
      .get(this.config.config.loader.videoUrl, {
        responseType: "blob",
        onDownloadProgress,
      })
      .then((response) => {
        console.log(response);
        this.setState({
          loaderVideoSrc: URL.createObjectURL(response.data),
        });
      })
      .finally(() => {
        this.setState({
          loaderCanPlayVideo: true,
        });
      });
  }

  async componentDidMount() {
    window.addEventListener("fullscreenchange", () => {
      sendGoogleData(
        "Skoda_360",
        "fullscreen",
        undefined,
        this.state.fullScreenEnabled ? "on" : "off",
        false
      );
    });
    this.iosZoomHack();

    loadingProgress.setUpdateStateFunction(this.setState.bind(this));

    /**
     * Похоже на то, что здесь это не нужно
     * await this.setSpeed(5);
     */

    const container = this.containerRef.current;
    if (container) {
      this.fullscreenchangeHandler = this.onFullScreenChange.bind(this);
      container.addEventListener(
        "fullscreenchange",
        this.fullscreenchangeHandler,
        false
      );
      container.addEventListener(
        "mozfullscreenchange",
        this.fullscreenchangeHandler,
        false
      );
      container.addEventListener(
        "webkitfullscreenchange",
        this.fullscreenchangeHandler,
        false
      );
      container.addEventListener(
        "msfullscreenchange",
        this.fullscreenchangeHandler,
        false
      );
    }

    this.config
      .load()
      .then(() => {
        this.setState((state) => ({
          configIsLoading: false,
          loaderVideoSrc: this.config.config.loader.videoUrl,
          menuItems: state.menuItems.map((item) => ({
            ...item,
            disable:
              this.config.config[item.id.toLowerCase()] &&
              this.config.config[item.id.toLowerCase()].disable,
          })),
        }));

        sendGoogleData(
          "Skoda_360",
          "open_icon_click",
          undefined,
          this.config.config.name,
          false
        );

        this.bumpIsMobile();
        window.addEventListener("resize", this._onResize);
        this.animationFrameId = window.requestAnimationFrame(
          this.animate.bind(this)
        );
      })
      .then(() => {
        this.firstOffroad = false;
        const params = new URLSearchParams(window.location.search);
        const start = params.get("initialMode");
        const startPoints = {
          virtualtestdrive: 0,
          exterior: 1,
          interior: 2,
          onlyvirtualtestdrive: 0,
        };

        if (start === "full" || start === "onlyvirtualtestdrive") {
          this.setState((state) => {
            const updatedMenuItems = [
              { id: "offroad", active: false, title: "OFF-ROAD" },
              ...state.menuItems,
            ];

            return {
              menuItems:
                state.menuItems[0].id === "offroad"
                  ? state.menuItems
                  : updatedMenuItems,
              onlyvirtualtestdrive: start === "onlyvirtualtestdrive",
            };
          });
        }

        // this.setState({modelName: this.config.config["name"]})
        if (start && start in startPoints) {
          const startNum = startPoints[start];
          this.onChangeMenu(startNum);
          this.firstOffroad = startNum === 0;
        }
      })
      .then(() => {
        if (this.config.config.loader.videoUrl) {
          this.preloadVideo();
        } else {
          this.setState({
            loaderCanPlayVideo: true,
          });
        }
      });
  }

  componentWillUnmount() {
    const container = this.containerRef.current;
    if (container) {
      container.removeEventListener(
        "fullscreenchange",
        this.fullscreenchangeHandler,
        false
      );
      container.removeEventListener(
        "mozfullscreenchange",
        this.fullscreenchangeHandler,
        false
      );
      container.removeEventListener(
        "webkitfullscreenchange",
        this.fullscreenchangeHandler,
        false
      );
      container.removeEventListener(
        "msfullscreenchange",
        this.fullscreenchangeHandler,
        false
      );
    }
    window.removeEventListener("resize", this._onResize);
    window.cancelAnimationFrame(this.animationFrameId);
  }
}

export default App;
