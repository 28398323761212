import './InfoSpot.scss';
import React from 'react'

export default class InfoSpot extends React.Component {

  static defaultProps = {
    isShow: false,
    isViewed: false,
    id: '0',
    config: {},
    isMobile: false,
    position: [0, 0],
    positionType: '%',
    type: 'default',
    onClick: () => null,
    onMouseEnter: () => null,
    onMouseLeave: () => null,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {id, config, position, positionType, isShow, isMobile, isViewed,
      onClick, type, onMouseEnter, onMouseLeave} = this.props;
    const sizePercent = isMobile ? config.mobileSizePercent : 1;
    const size = sizePercent * config.size;
    let classes = ['sk-info-spot'];
    if (isShow) {
      classes.push('sk-info-spot--show');
    }
    if (type === 'video') {
      classes.push('sk-info-spot--video');
    }
    if (isMobile) {
      classes.push('sk-info-spot--mobile');
    }
    if (isViewed) {
      classes.push('sk-info-spot--viewed');
    }
    return <div
      key={id}
      className={classes.join(' ')}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        left: `calc(${position[0]}${positionType} - ${size/2}px)`,
        top: `calc(${position[1]}${positionType} - ${size/2}px)`
      }}
      data-key={id}
      onClick={(e) => onClick(e, id, this)}
      onMouseEnter={(e) => onMouseEnter(e, id, this)}
      onMouseLeave={(e) => onMouseLeave(e, id, this)}
    />;
  }
}
