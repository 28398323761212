import Hls from "hls.js";
import AFRAME from "aframe";
import localforage from "localforage";
import { loadingProgress } from "./LoadingProgress";
import axios from "axios";
import { sendGoogleData } from "./ga";

export default class Offroad {
  register(loaderUrl, cspeed, onlyvirtualtestdrive, modelName, config) {
    if (!window.AFRAME.components["video-controller"]) {
      let isFirstLoad = true;
      // let db;
      AFRAME.registerComponent("video-controller", {
        schema: {
          ldata: { type: "string", default: "./data/20210326_KAROQ.json" },
          // playb: { type: 'selector', default: '#playb' },
          cache2D: { default: false },
          loader: { default: false },
          linkdata: { default: true },
          // startButton: { default: false },
          lowSpeedTime: { default: 30 },
          startQuality: { default: 2 },
        },
        init: async function () {
          // this.setTitle()

          //fullscreenHandler
          this.fullscreenButton = document.getElementsByClassName(
            "sk-fullscreen-button"
          )[0];
          this.fsSendData = this.fullscrenSendData.bind(this);
          this.fullscreenButton.addEventListener("click", this.fsSendData);

          //can Start
          this.canStart = true;

          //activitySpeedChecker
          this.activitySpeedChecker = 0;

          //check speed
          this.speed =
            "undefined" !== typeof cspeed ? cspeed : await this.checkSpeed();
          console.log(this.speed);

          //check ios version
          this.videoType = this.checkIOSVersion();
          console.log("videoType", this.videoType);

          // zoom hack
          document.addEventListener(
            "touchmove",
            function (event) {
              // console.log("nek")
              if (AFRAME.utils.device.isIOS()) {
                // console.log("kek")
                if (event.scale !== 1) {
                  event.preventDefault();
                }
              }
            },
            { passive: false }
          );

          // main part
          this.debugMessage = document.querySelector(".message");
          let ldata = this.data.ldata;
          if (this.data.linkdata) {
            let params = new URLSearchParams(location.search);
            let murl = params.get("offroadURL");
            let offroadLoader = params.get("offroadLoader");
            ldata = murl ? murl : this.data.ldata;

            ldata = config ? config : ldata; // data from main json
            this.data.loader = offroadLoader
              ? "true" == params.get("offroadLoader")
              : this.data.loader;
            // console.log("params", ldata);

            const vt = params.get("videoType");
            // console.log(vt,'vt')

            this.videoType = vt ? vt : this.videoType;

            // let startQuality = params.get('startQuality')
            // this.data.startQuality =
            //   startQuality && NaN != parseInt(startQuality, 10)
            //     ? parseInt(startQuality, 10)
            //     : this.data.startQuality
          }

          console.log("real videoType", this.videoType);

          this.loaderVideo = document.getElementById("loaderVideo");

          this.loaderText = document.querySelector(".persent-text");

          this.spinner = document.getElementById("spinner");

          this.videoPanel = document.querySelector(".video-panel");

          this.finishPanel = document.querySelector(".finish-panel");
          this.lowSpeedPanel = document.querySelector(".low-speed-panel");

          let offroadMenu = document.querySelector(".offroad-menu");
          // offroadMenu.style.display = "none";
          this.activeVideo = 0;

          this.pauseButton = document.getElementById("pauseButton");
          this.finishButton = document.getElementById("finishButton");
          this.restartButton = document.getElementById("restartButton");
          this.lowSpeedButton = document.getElementById("lowSpeedButton");
          this.closeButton = document.getElementById("closeButton");
          this.closeOffroadButton =
            document.getElementById("closeOffroadButton");
          this.closeVideoButton = document.getElementById("closeVideoButton");
          this.soundButton = document.getElementById("soundButton");
          this.resolutionButton = document.getElementById("resolutionButton");

          // this.player360 = this.data.player360;
          this.blockCamera =
            document.querySelector("a-scene").components["block-camera"];

          // let playb = this.data.playb
          // playb.setAttribute('scale', '0 0 0')
          // this.addScaleAnimation(playb)
          // this.addMouseAnimation(playb.firstElementChild)
          // playb.emit('scaleUp')

          fetch(ldata)
            .then((res) => res.json())
            .then((vdata) => {
              this.vdata = vdata;

              this.loadIcons();

              this.checkLowQuality();
              this.setStartQuality();

              this.loaderVideo.src =
                "undefined" !== typeof loaderUrl
                  ? loaderUrl
                  : vdata.loaderVideo;
              this.loaderVideo.load();

              let videos = [
                {
                  video: document.getElementById("video1"),
                  button: document.getElementById("insideButton"),
                  vsphere: document.getElementById("vsphere1"),
                  links: this.vdata.inside.links,
                  hls: this.vdata.inside.hls,
                  name: "inside",
                },
                {
                  video: document.getElementById("video1"),
                  button: document.getElementById("outsideButton"),
                  vsphere: document.getElementById("vsphere1"),
                  links: this.vdata.outside.links,
                  hls: this.vdata.outside.hls,
                  name: "outside",
                },
                {
                  video: document.getElementById("video1"),
                  button: document.getElementById("droneButton"),
                  vsphere: document.getElementById("vsphere1"),
                  links: this.vdata.drone.links,
                  hls: this.vdata.drone.hls,
                  name: "drone",
                },
                {
                  video: document.getElementById("video4"),
                  button: document.getElementById("pointButton"),
                  name: "usp",
                },
              ];
              this.videos = videos;

              this.currVideoNum = 0;

              // this.vdata = JSON.parse(data);
              // vdata = this.vdata;

              this.audio2D = document.createElement("audio");
              this.audio2D.src = this.vdata.audio2D
                ? this.vdata.audio2D
                : "https://skoda-cdn.riftman.ru/1111content/Kodiaq/Music/Cinematic%20Inspiration%20(Main).mp3";
              this.audio2D.type = "audio/mpeg";
              this.audio2D.preload = "auto";
              this.audio2D.loop = true;
              this.videoPanel.appendChild(this.audio2D);

              this.vdata.drone.timecodes.forEach((p) => {
                p.start = this.convertTime(p.start);
                p.stop = this.convertTime(p.stop);
              });

              this.vdata.points.forEach((p) => {
                p.timecode.start = this.convertTime(p.timecode.start);
                p.timecode.stop = this.convertTime(p.timecode.stop);
              });

              document.addEventListener("keydown", (e) => {
                if (e.key == "e")
                  videos[this.activeVideo].video.currentTime += 10;
              });

              let startOffroad = async () => {
                this.loaderVideo.parentElement.style.display = "block";
                // this.spinner.classList.add('my-spinner-active')
                this.loaderVideo.play();

                this.resolutionButton.style.visibility = "hidden";
                this.loaderText.style.display = "none";
                this.newLoader = await this.loadVideo(this.vdata.loaderChanger);

                // playb.emit('scaleDown')
                // playb.classList.remove('interractible')

                this.preload(() => {
                  // this.audio2D.play()
                  // this.audio2D.pause()

                  this.loaderText.style.display = "none";
                  offroadMenu.classList.add("offroad-menu-visible");

                  // for (let i=this.videos.length-2; i>=0; i--) {
                  //   this.changeVideo(i);
                  // }

                  if (this.videoType != "hls") {
                    let setQualitty = (q) => {
                      this.currentQuality = q;
                      this.resolutionButton.innerHTML = this.vdata.quality[q];
                      this.resolutionButton.setAttribute("quality", q);
                    };
                    setQualitty(this.data.startQuality);
                    this.resolutionButton.removeAttribute("disabled");

                    this.resolutionButton.addEventListener("click", () => {
                      setQualitty(
                        this.currentQuality < this.vdata.quality.length - 1
                          ? ++this.currentQuality
                          : 0
                      );
                      this.changeVideo(this.activeVideo, true);
                    });

                    this.cTime = 0;
                    this.videos[0].video.src =
                      this.videos[0].links[this.currentQuality];
                    this.videos[0].video.play();
                  } else {
                    this.loadHls(
                      this.videos[0].video,
                      this.videos[0].hls,
                      undefined,
                      () => {
                        this.videos[0].video.play();
                      }
                    );
                  }

                  videos[0].video.addEventListener("playing", () => {
                    loadingProgress.clearProgress();
                    //   console.log('playing')

                    // if (i == this.activeVideo) {
                    this.loaderVideo.pause();
                    this.spinner.classList.remove("my-spinner-active");
                    this.loaderVideo.parentElement.style.display = "none";
                    this.resolutionButton.style.visibility = "visible";

                    if (!this.started && this.vdata.loaderChanger) {
                      this.started = true;
                      // this.loaderVideo.src = this.newLoader
                      this.loaderVideo.src = this.vdata.loaderChanger;
                    }

                    // console.log("playing");

                    // this.canPlay = true;

                    // }
                  });

                  videos[0].video.addEventListener("waiting", () => {
                    //   console.log('waiting')
                    if (videos[0].video.paused) return;

                    if (isFirstLoad) {
                      isFirstLoad = false;
                      return;
                    }

                    this.loaderVideo.play();
                    this.spinner.classList.add("my-spinner-active");
                    this.loaderVideo.parentElement.style.display = "block";
                    this.resolutionButton.style.visibility = "hidden";
                  });

                  // videos[i].video.addEventListener("seeking", _=> {
                  //   if (i == this.activeVideo) {
                  //     // this.loaderVideo.parentElement.style.display = "block";
                  //     // this.loaderVideo.play();
                  //     // console.log("seeking");

                  //   }}
                  // );

                  //   videos[i].video.addEventListener("seeked", _=> {
                  //     if (i == this.activeVideo) {
                  //       this.loaderVideo.pause();
                  //       // console.log("seeked");

                  //       // this.spinner.classList.remove("my-spinner-active");
                  //       this.loaderVideo.parentElement.style.display = "none";
                  //       this.canPlay = true;

                  //     }
                  //   });

                  // }

                  // videos[3].video.addEventListener("seeking", _=> {
                  //   console.log("2dVideoSeeking");

                  // });

                  // videos[3].video.addEventListener("seeked", _=> {
                  //   console.log("2dVideoSeeked");

                  // });

                  videos[3].video.addEventListener("playing", () => {
                    // console.log("2dVideoPlaying");
                    this.spinner.classList.remove("my-spinner-active");
                  });

                  videos[3].video.addEventListener("waiting", () => {
                    // console.log("2dVideoWaiting");
                    this.spinner.classList.add("my-spinner-active");
                  });

                  videos[3].video.addEventListener("timeupdate", () => {
                    // console.log("2dVideoWaiting");
                    this.spinner.classList.remove("my-spinner-active");
                  });
                });

                // offroadMenu.style.display = "flex";
                // offroadMenu.style.opacity = "1";
              };

              // if (this.data.startButton) {
              //   playb.addEventListener('click', startOffroad)
              // } else {
              // startOffroad()
              this.showInstruction(startOffroad);
              // }

              for (let i = 0; i < videos.length; i++) {
                videos[i].button.addEventListener("click", () =>
                  this.changeVideo(i)
                );
              }

              const end2dVideo = () => {
                this.audio2D.pause();
                this.videos[3].video.pause();

                this.videoPanel.firstElementChild.classList.remove(
                  "show-video-container"
                );
                for (let i = 0; i < this.videos.length; i++) {
                  this.videos[i].button.classList.remove("active");
                }
                if (this.videoType == "mp4") {
                  this.resolutionButton.style.display = "block";
                }
                this.videos[this.activeVideo].button.classList.add("active");
                // this.currVideoNum = this.activeVideo;
                setTimeout(() => {
                  this.videoPanel.classList.remove("show-video-panel");
                  this.videos[this.activeVideo].video.play();
                  this.currVideoNum = this.activeVideo;
                }, 400);
              };

              videos[3].video.addEventListener("ended", () => {
                // this.changeVideo(this.activeVideo)
                this.currentPoint.ended = true;
                end2dVideo();
                sendGoogleData(
                  "VirtualTestDrive",
                  "additionInfoClose",
                  this.getTimeslot(),
                  this.popupName,
                  true
                );
              });

              this.closeVideoButton.addEventListener("click", () => {
                end2dVideo();
                sendGoogleData(
                  "VirtualTestDrive",
                  "additionInfoClose_icon_click",
                  this.getTimeslot(),
                  this.popupName
                );
              });

              // for (let i=0; i < this.videos.length-1; i++) {
              // console.log("hls",videos[i].hls);

              videos[0].video.addEventListener("ended", () => {
                this.finishPanel.classList.add("panel-active");
                sendGoogleData(
                  "VirtualTestDrive",
                  "finalPopup_showed",
                  undefined,
                  undefined,
                  true
                );
              });

              videos[0].video.addEventListener("timeupdate", () => {
                // if (AFRAME.utils.device.isIOS() && this.videoType == 'hls') {
                //   this.loaderVideo.parentElement.style.display = 'none'
                //   this.resolutionButton.style.visibility = 'visible'
                // }

                // console.log(videos[0].vsphere.components.material)

                // videos[0].vsphere.setAttribute('material', 'src: #video2');
                // videos[0].vsphere.setAttribute('material', 'src: #video1');

                if (++this.activitySpeedChecker == 20) {
                  sendGoogleData(
                    "VirtualTestDrive",
                    undefined,
                    this.getTimeslot(),
                    undefined,
                    true,
                    "iframe_activity"
                  );
                  this.activitySpeedChecker = 0;
                }

                if (AFRAME.utils.device.isIOS()) {
                  this.loaderVideo.parentElement.style.display = "none";
                  this.spinner.classList.remove("my-spinner-active");
                  this.loaderVideo.pause();
                  // videos[0].video.play()
                }

                // this.debugMessage.innerHTML = this.activeVideo;
                // if (this.activeVideo != i)
                //    return;

                if (this.debugMessage)
                  this.debugMessage.innerHTML =
                    0 + " " + videos[0].video.currentTime;
                let time = videos[0].video.currentTime;
                // console.log("hc", this.videos[0].hls.currentLevel, this.videos[1].hls.currentLevel, this.videos[2].hls.currentLevel);
                let droneShown = false;
                let pointShown = false;
                // this.debugMessage.innerHTML = this.vdata;

                this.vdata.drone.timecodes.forEach((p) => {
                  // this.debugMessage.innerHTML = time;
                  // this.debugMessage.innerHTML += " " + p.start + " " + p.stop;
                  if (time > p.start && time < p.stop) {
                    videos[2].button.removeAttribute("disabled");
                    if (p.empty) videos[2].button.setAttribute("empty", null);
                    else videos[2].button.removeAttribute("empty");

                    if (!p.activate) {
                      this.showHideTooltip(videos[2].button, true);
                      sendGoogleData(
                        "VirtualTestDrive",
                        "quadrocoptView_icon_flashed",
                        this.getTimeslot(),
                        undefined,
                        true
                      );
                    }
                    p.activate = true;
                    droneShown = true;
                  }
                });
                if (!droneShown) {
                  videos[2].button.setAttribute("disabled", null);
                  this.showHideTooltip(videos[2].button, false);
                }
                // console.log(
                //   'drone shown',
                //   droneShown,
                //   this.activeVideo,
                //   this.droneActive,
                //   videos[0].video.currentTime
                // )

                if (
                  this.activeVideo == 2 &&
                  !droneShown &&
                  videos[0].video.currentTime != 0
                ) {
                  this.changeVideo(0, false, null, true);
                }

                // if (
                //   this.droneActive &&
                //   !droneShown &&
                //   videos[0].video.currentTime != 0
                // ) {
                //   this.droneActive = false
                //   this.changeVideo(0)
                // }

                this.vdata.points.forEach((p) => {
                  if (time > p.timecode.start && time < p.timecode.stop) {
                    videos[3].button.removeAttribute("disabled");
                    videos[3].button.querySelector("img").src =
                      this.vdata.icons[p.icon];

                    if (!p.activate) {
                      this.currentPoint = p;

                      videos[3].video.src = p.link ? p.link : "https://null/";
                      this.noGlobalMusic = p.noGlobalMusic;
                      let pName = p.name ? `\n \"${p.name}\"` : "";
                      this.showHideTooltip(
                        videos[3].button,
                        true,
                        p.type + pName
                      );

                      this.popupName = p.link.substring(
                        p.link.lastIndexOf("/") + 1
                      );
                      if (this.popupName.indexOf(".") != -1)
                        this.popupName = this.popupName.split(".")[0];
                      sendGoogleData(
                        "VirtualTestDrive",
                        "additionInfo_flashed",
                        this.getTimeslot(),
                        this.popupName,
                        true
                      );

                      if (p.autoOpen && !p.autoOpened) {
                        this.changeVideo(3, false, null, true);
                        // p.autoOpened = true
                      }
                    }
                    p.activate = true;
                    pointShown = true;
                  }
                });

                if (!pointShown || this.currentPoint.ended) {
                  videos[3].button.setAttribute("disabled", null);
                  this.showHideTooltip(videos[3].button, false);
                }
              });

              //  }

              this.pauseButton.addEventListener("click", () => {
                let activeVideo = this.videos[this.activeVideo].video;
                if (activeVideo.paused) {
                  activeVideo.play();
                  this.pauseButton.querySelector("img").src =
                    this.vdata.icons.pause;
                  sendGoogleData(
                    "VirtualTestDrive",
                    "pause_icon_click",
                    this.getTimeslot(),
                    "play"
                  );
                } else {
                  activeVideo.pause();
                  this.pauseButton.querySelector("img").src =
                    this.vdata.icons.play;
                  sendGoogleData(
                    "VirtualTestDrive",
                    "pause_icon_click",
                    this.getTimeslot(),
                    "pause"
                  );
                }
              });

              this.finishButton.addEventListener("click", () => {
                sendGoogleData(
                  "VirtualTestDrive",
                  "finalPopup_testdrive_btn_click"
                );
                // TODO: find out where is set finishLink
                // top.location.href =
                //   this.vdata.finishLink || "https://www.skd-avto.ru/test-drive";
                top.location.href = "https://www.skd-avto.ru/test-drive";
              });

              const closeOffRoad = () => {
                console.log(onlyvirtualtestdrive, "ooo");
                if (!onlyvirtualtestdrive) {
                  let event = new Event("closeOffroad", {
                    composed: true,
                    bubbles: true,
                  });
                  console.log("th", this);
                  this.el.dispatchEvent(event);
                } else {
                  //TODO: find out where is set backLink
                  // top.location.href =
                  //   this.vdata.backLink || "https://www.skd-avto.ru/test-drive";
                  top.location.href = "https://www.skd-avto.ru";
                }
              };

              this.lowSpeedButton.addEventListener("click", closeOffRoad);

              this.closeButton.addEventListener("click", () => {
                sendGoogleData(
                  "VirtualTestDrive",
                  "end_link_click",
                  this.getTimeslot()
                );
                closeOffRoad();
              });

              this.closeOffroadButton.addEventListener("click", () => {
                sendGoogleData(
                  "VirtualTestDrive",
                  "finalPopup_close_icon_click"
                );
                closeOffRoad();
              });

              this.restartButton.addEventListener("click", () => {
                sendGoogleData(
                  "VirtualTestDrive",
                  "finalPopup_retry_btn_click"
                );

                this.changeVideo(0, true, 0);
                this.finishPanel.classList.remove("panel-active");

                this.vdata.drone.timecodes.forEach((p) => {
                  p.activate = false;
                });

                this.vdata.points.forEach((p) => {
                  p.activate = false;
                  p.ended = false;
                });
              });

              this.muted = false;

              this.soundButton.addEventListener("click", () => {
                this.muted = !this.muted;
                sendGoogleData(
                  "VirtualTestDrive",
                  "sound_icon_click",
                  this.getTimeslot(),
                  this.muted ? "mute" : "unmute"
                );
                this.soundButton.querySelector("img").src = this.muted
                  ? this.vdata.icons.offSound
                  : this.vdata.icons.onSound;
                for (let i = 0; i < this.videos.length; i++) {
                  videos[i].video.muted = this.muted;
                }
                this.audio2D.muted = this.muted;
              });
            })
            .catch((error) => {
              console.log("FAIL", error);
            });
        },
        // addScaleAnimation: function (item) {
        //   item.setAttribute('animation__scaleUp', {
        //     property: 'scale',
        //     startEvents: 'scaleUp',
        //     dur: 400,
        //     to: '1 1 1',
        //   })
        //   item.setAttribute('animation__scaleDown', {
        //     property: 'scale',
        //     startEvents: 'scaleDown',
        //     dur: 400,
        //     to: '0 0 0',
        //   })
        // },

        // addMouseAnimation: function (item) {
        //   item.setAttribute('animation__mouseenter', {
        //     property: 'scale',
        //     startEvents: 'mouseenter',
        //     dur: 200,
        //     to: '1.1 1.1 1.1',
        //   })

        //   item.setAttribute('animation__mouseleave', {
        //     property: 'scale',
        //     startEvents: 'mouseleave',
        //     dur: 200,
        //     to: '1 1 1',
        //   })
        // },

        loadHls: function (video, url, sp, nextAction) {
          var config = {
            startPosition: sp ? sp : -1,
          };

          var videoSrc = url;
          if (Hls.isSupported()) {
            var hls = new Hls(config);

            hls.startLevel = 3;
            //   hls.currentLevel = 5;
            hls.loadSource(videoSrc);
            hls.attachMedia(video);
            // console.log("video", video);
            hls.on(Hls.Events.MANIFEST_PARSED, function () {
              if (nextAction) nextAction();
              // if (play) video.play()
            });
            return hls;
          } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
            video.src = videoSrc;
            video.addEventListener("loadedmetadata", function () {
              if (nextAction) nextAction();
              // if (play) video.play()
            });
          }
        },

        changeVideo: function (
          index,
          res = false,
          time = null,
          auto2D = false
        ) {
          // console.log('v1',this.canStart)
          if (!this.canStart) return;
          this.canStart = false;
          // setTimeout(()=>{
          //   this.canStart = true
          //   console.log('v2',this.canStart)
          // },1000)

          // console.log('v1',this.currVideoNum)
          // if (this.currVideoNum == index && !res) return
          // let oldVideoNum = this.currVideoNum;
          // this.currVideoNum = index;

          let videos = this.videos;
          let block = index == 0;
          let videoObj = this.videos[index];

          const buttonActions = [
            "interior_view_btn_click",
            "street_view_btn_click",
            "quadrocoptView_icon_click",
            "additionInfo_icon_click",
          ];
          // console.log(index, videos[3].video.src);

          if (
            (index == 3 && videos[3].video.src == "https://null/") ||
            (index == 2 && videos[2].button.hasAttribute("empty")) ||
            (videoObj.button.classList.contains("active") && !res)
          ) {
            // this.currVideoNum = oldVideoNum;
            this.canStart = true;
            return;
          }

          // console.log('v2',this.currVideoNum)

          // if (this.videoStarting) return

          // this.videoStarting = true;
          if (index == 3)
            sendGoogleData(
              "VirtualTestDrive",
              buttonActions[index],
              this.getTimeslot(),
              this.popupName,
              auto2D
            );
          this.currVideoNum = index;
          if (index != 3)
            sendGoogleData(
              "VirtualTestDrive",
              buttonActions[index],
              this.getTimeslot(),
              undefined,
              auto2D
            );

          for (let i = 0; i < videos.length; i++) {
            videos[i].button.classList.remove("active");
            videos[i].video.pause();
          }
          videoObj.button.classList.add("active");
          // this.debugMessage.innerHTML = index + " " + videos[index].video;
          this.pauseButton.querySelector("img").src = this.vdata.icons.pause;

          this.showHideTooltip(videos[index].button, false);

          if (index == 3) {
            this.videoPanel.classList.add("show-video-panel");
            if (this.videoType == "mp4") {
              this.resolutionButton.style.display = "none";
            }

            this.videoPanel.firstElementChild.classList.add(
              "show-video-container"
            );
            if (!this.noGlobalMusic) {
              this.audio2D.play();
            }
            videoObj.video.play();
            this.videoStarting = false;
          } else if (this.videoType == "hls") {
            this.cTime =
              time != null ? time : videos[this.activeVideo].video.currentTime;
            console.log("hls interes", this.cTime);

            // console.log(videoObj)
            const agent = window.navigator.userAgent;

            this.loadHls(videoObj.video, videoObj.hls, this.cTime, () => {
              this.activeVideo = index;

              let isIPhone =
                AFRAME.utils.device.isIOS() && agent.indexOf("iPad") == -1;

              if (isIPhone) {
                videoObj.video.addEventListener(
                  "canplay",
                  () => {
                    // console.log('hot', this.cTime)
                    videoObj.video.currentTime = this.cTime;
                    // videoObj.video.play()
                  },
                  { once: true }
                );

                videoObj.video.addEventListener(
                  "timeupdate",
                  () => {
                    // console.log('update', this.cTime)
                    // setTimeout(() => {
                    videoObj.video.play();
                    // }, 50)
                  },
                  { once: true }
                );
                // videoObj.video.play()
              } else {
                videoObj.video.play();
              }
              this.videoStarting = false;

              // if (index == 2) this.droneActive = true
            });
            this.blockCamera.block(block);
          } else {
            this.cTime =
              time != null ? time : videos[this.activeVideo].video.currentTime;
            // console.log("qua", this.currentQuality);
            videoObj.video.src = videoObj.links[this.currentQuality];
            videoObj.video.currentTime = this.cTime;
            videoObj.video.load();

            this.loaderVideo.parentElement.style.display = "block";
            this.spinner.classList.add("my-spinner-active");
            this.loaderVideo.play();

            this.resolutionButton.style.visibility = "hidden";
            // if (index == 2) this.droneActive = true

            videoObj.video.addEventListener(
              "canplay",
              () => {
                console.log("hot", this.cTime);
                videoObj.video.currentTime = this.cTime;
                // videoObj.video.play();
              },
              { once: true }
            );

            videoObj.video.addEventListener(
              "timeupdate",
              () => {
                console.log("update", this.cTime);
                videoObj.video.play();
                this.videoStarting = false;
              },
              { once: true }
            );

            this.activeVideo = index;

            this.blockCamera.block(block);
          }

          this.canStart = true;
        },

        tick: function () {
          if (this.videos) {
            this.videos[0].vsphere.setAttribute("material", "src: #video2");
            this.videos[0].vsphere.setAttribute("material", "src: #video1");
          }
        },

        convertTime: function (time) {
          let t = time.split(":");
          return parseInt(t[0]) * 60 + parseInt(t[1]) + parseInt(t[2]) / 100;
        },

        showHideTooltip: function (target, show, data) {
          if (data) {
            target.setAttribute("data-tooltip", data);
          }
          if (show) {
            target.classList.add("showtip");
          } else {
            target.classList.remove("showtip");
          }
        },

        loadIcons: function () {
          let icons = this.vdata.icons;
          document.querySelector("#globe").src = icons.globe;
          document.querySelector("#hand").src = icons.hand;
          document.querySelector("#fakeInside img").src = icons.inside;
          document.querySelector("#fakeOutside img").src = icons.outside;
          document.querySelector("#fakeDrone img").src = icons.drone;
          document.querySelector("#fakeObstacle img").src = icons.obstacle;
          document.querySelector("#fakePoint img").src = icons.point;

          document.querySelector("#insideButton img").src = icons.inside;
          document.querySelector("#outsideButton img").src = icons.outside;
          document.querySelector("#pauseButton img").src = icons.pause;
          document.querySelector("#droneButton img").src = icons.drone;
          document.querySelector("#pointButton img").src = icons.obstacle;
          document.querySelector("#soundButton img").src = icons.onSound;
          document.querySelector("#closeVideoButton img").src = icons.close;
          document.querySelector("#closeButton img").src = icons.back;
          document.querySelector("#closeButton img").src = icons.back;

          document.querySelector(".up-pannel").style.opacity = "1";
        },

        preload: function (callback) {
          // this.loadIcons()

          if (!this.data.loader) {
            callback();
            return;
          }

          let lvideo = this.loaderVideo;
          let vdata = this.vdata;

          lvideo.parentElement.style.display = "block";
          this.spinner.classList.remove("my-spinner-active");
          lvideo.play();

          let loaderText = this.loaderText;

          loadVideo = (lvideo, vdata, callback, l, num) => {
            // console.log(num);
            // console.log(Math.round(num/vdata.points.length * 100) + "%");
            // console.log(this.loaderText.innerHTML);
            let currPerc = Math.round((num / vdata.points.length) * 100);

            loaderText.innerHTML = currPerc + "%";

            if (num < vdata.points.length) {
              fetch(vdata.points[num].link)
                .then((response) => {
                  try {
                    const contentEncoding =
                      response.headers.get("content-encoding");
                    const contentLength = response.headers.get(
                      contentEncoding ? "x-file-size" : "content-length"
                    );

                    const total = parseInt(contentLength, 10);
                    let loaded = 0;

                    return new Response(
                      new ReadableStream({
                        start(controller) {
                          const reader = response.body.getReader();
                          read();
                          function read() {
                            reader
                              .read()
                              .then(({ done, value }) => {
                                if (done) {
                                  controller.close();
                                  return;
                                }
                                loaded += value.byteLength;
                                let l = 1 / vdata.points.length;
                                let per = Math.round(
                                  Math.round((loaded / total) * 100) * l
                                );
                                // console.log( currPerc + per + "%");
                                loaderText.innerHTML = currPerc + per + "%";

                                controller.enqueue(value);
                                read();
                              })
                              .catch((error) => {
                                console.error(error);
                                controller.error(error);
                              });
                          }
                        },
                      })
                    );
                  } catch {
                    console.log("some error");
                  }
                })
                .then((response) => response.blob())
                .then((value) => {
                  value = new Blob([value], { type: "video/mp4" });
                  let vid = vdata.points[num].link
                    ? URL.createObjectURL(value)
                    : "https://null/";
                  vdata.points[num].link = vid;
                  localforage.setItem(
                    "video" + num,
                    vdata.points[num].link ? value : "https://null/",
                    () => {}
                  );

                  num += 1;
                  l(lvideo, vdata, callback, l, num);
                })
                .catch((error) => {
                  console.log("FAILOAD", error);
                });
            } else {
              // console.log(vdata)

              lvideo.parentElement.style.display = "none";
              lvideo.pause();
              // loaderText.style.display ="none";
              callback();
            }
          };
          if (!this.data.cache2D) {
            loadVideo(lvideo, vdata, callback, loadVideo, 0);
            return;
          }
          localforage.getItem(
            "video" + (vdata.points.length - 1),
            function (err, value) {
              // console.log(value);
              if (!value) {
                loadVideo(lvideo, vdata, callback, loadVideo, 0);
              } else {
                for (let i = 0; i < vdata.points.length; i++) {
                  localforage.getItem("video" + i, function (err, value) {
                    let vid = vdata.points[num].link;
                    value == "https://null/"
                      ? value
                      : URL.createObjectURL(value);
                    vdata.points[i].link = vid;
                    if (i == vdata.points.length - 1) {
                      // console.log(vdata);
                      lvideo.parentElement.style.display = "none";
                      lvideo.pause();
                      // loaderText.style.display ="none";

                      callback();
                    }
                  });
                }
              }
            }
          );
        },

        checkSpeed: () =>
          new Promise((resolve) => {
            const userImageLink =
              "https://vtd-static.skoda-avto.ru/auto24/TestDrive/Skoda_Enyaq.jpg";
            let time_start, end_time;

            // The size in bytes
            const downloadSize = 1579199;
            const downloadImgSrc = new Image();

            time_start = new Date().getTime();
            downloadImgSrc.width = 360;
            downloadImgSrc.height = 200;
            downloadImgSrc.src = userImageLink + "?i=" + time_start;

            downloadImgSrc.onload = () => {
              end_time = new Date().getTime();
              const timeDuration = (end_time - time_start) / 1000;
              const loadedBits = downloadSize * 8;

              /* Converts a number into string
                using toFixed(2) rounding to 2 */
              const bps = (loadedBits / timeDuration).toFixed(2);
              const speedInKbps = (bps / 1024).toFixed(2);
              const speedInMbps = (speedInKbps / 1024).toFixed(2);
              console.log("speed", speedInMbps);
              resolve(speedInMbps);
            };
          }),

        checkIOSVersion: () => {
          const agent = window.navigator.userAgent,
            start = agent.indexOf("OS ");

          let v = "hls";
          if (
            (agent.indexOf("iPhone") > -1 || agent.indexOf("iPad") > -1) &&
            start > -1
          ) {
            const version = window.Number(
              agent.substr(start + 3, 5).replace("_", ".")
            );
            console.log("version: ", version);
            const height = window.screen.height * window.devicePixelRatio;
            const width = window.screen.width * window.devicePixelRatio;
            // iPhone 12 Pro Max: 1284 x 2778
            // iPhone 12 Pro: 1170 x 2532
            // iPhone 12: 1170 x 2532
            // iPhone 12 Mini: 1080 x 2430
            const hlsResolutions = [1284, 2778, 1170, 2532, 2430];
            switch (version) {
              case "14.0":
              case "14.0.1":
              case "14.1":
              case "14.2.1":
                v = "mp4"; // HLS not supported on these iOS versions; falling back to mp4
                break;
            }
            if (
              hlsResolutions.includes(height) ||
              hlsResolutions.includes(width)
            ) {
              // HLS not supported on iPhone 12 devices; falling back to mp4
              console.log("12 iphone");
              v = "mp4";
            }
            return v;
          }
          return v;
        },

        setStartQuality: function () {
          let params = new URLSearchParams(location.search);
          let startQuality = params.get("startQuality");
          this.data.startQuality =
            startQuality && NaN != parseInt(startQuality, 10)
              ? parseInt(startQuality, 10)
              : this.vdata.qualityMinSpeed
              ? this.vdata.qualityMinSpeed.findIndex((e) => this.speed < e)
              : this.data.startQuality;
          console.log(this.data.startQuality);
        },

        checkLowQuality: function () {
          console.log("s");
          if (this.vdata.minSpeed && this.speed < this.vdata.minSpeed) {
            this.lowSpeedPanel.classList.add("panel-active");
          }
        },

        showInstruction: async function (playPromise, video) {
          // if (!playPromise) video.pause()
          // else {
          //   await playPromise
          //   video.pause()
          // }
          this.instructionPanel = document.querySelector(".instruction-panel");
          const header = this.instructionPanel.querySelector(".header-part");
          const parts = this.instructionPanel.querySelectorAll(".parts");
          const text = this.instructionPanel.querySelector(".text-part");
          const button =
            this.instructionPanel.querySelector("#instructionButton");
          const instructionWindow = this.instructionPanel.firstElementChild;
          const instructions = this.vdata.instructions;

          let pageNum = 0;
          let ended = false;
          let progress = false;

          const setParts = () => {
            if (ended || progress) return;
            progress = true;
            if (pageNum > 0)
              sendGoogleData("VirtualTestDrive", `step_${pageNum}_btn_click`);

            instructionWindow.style.opacity = 0;
            if (pageNum > instructions.length - 1) {
              ended = true;
              this.instructionPanel.classList.remove("panel-active");
              // video.play()
              playPromise();
              // this.resolutionButton.style.visibility = 'visible'
              // return
            }
            setTimeout(() => {
              instructionWindow.style.opacity = 1;
              this.resolutionButton.style.visibility = "hidden";
              if (pageNum > instructions.length - 1) {
                // this.instructionPanel.classList.remove('panel-active')
                // // video.play()
                // playPromise()
                this.resolutionButton.style.visibility = "visible";
                return;
              } else {
                header.innerHTML = instructions[pageNum].header;
                text.innerHTML = instructions[pageNum].text;
                button.innerHTML = instructions[pageNum].button;
                parts.forEach((p) => {
                  p.classList.remove("parts-active");
                });
                parts[pageNum].classList.add("parts-active");
              }
              pageNum++;
              progress = false;
            }, 500);

            // instructionWindow.style.opacity = 0

            // instructionWindow.classList.add('instruction-active')
          };

          button.addEventListener("click", setParts);
          button.addEventListener("click", this.deviceAsk);

          this.instructionPanel.classList.add("panel-active");

          setParts();
        },

        loadVideo: async (url) => {
          const onDownloadProgress = (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            loadingProgress.setProgress(percentCompleted);
          };

          let response = await axios.get(url, {
            responseType: "blob",
            onDownloadProgress,
          });

          let value = URL.createObjectURL(response.data);
          value = new Blob([value], { type: "video/mp4" });
          return URL.createObjectURL(value);
        },

        getTimeslot: function () {
          const num = this.currVideoNum;
          return (
            this.videos[num].name + "_" + this.videos[num].video.currentTime
          );
        },

        fullscrenSendData: function () {
          const isActive = this.fullscreenButton.classList.contains(
            "sk-fullscreen-button--entered"
          )
            ? "Window"
            : "fullScreen";
          sendGoogleData(
            "VirtualTestDrive",
            "fullScreen_icon_click",
            this.getTimeslot(),
            isActive
          );
        },

        remove: function () {
          this.fullscreenButton.removeEventListener("click", this.fsSendData);
        },

        // setTitle: function() {
        //   document.title = "This is the new page title.";
        // },

        deviceAsk: function () {
          // if (typeof DeviceMotionEvent.requestPermission === 'function') {
          //   DeviceMotionEvent.requestPermission()
          //     .then(permissionState => {
          //       if (permissionState === 'granted') {
          //         window.addEventListener('devicemotion', () => {});
          //       }
          //     })
          //     .catch(console.error);
          // }
          let self =
            document.getElementsByTagName("a-scene")[0].components[
              "device-orientation-permission-ui"
            ];
          if (self.permissionGranted) return;
          // console.log('ss',self)
          if (
            typeof DeviceOrientationEvent === "undefined" ||
            !DeviceOrientationEvent.requestPermission
          ) {
            this.permissionGranted = true;
            return;
          }
          DeviceOrientationEvent.requestPermission().then(() => {
            self.el.emit("deviceorientationpermissiongranted");
            self.permissionGranted = true;
          });
        },
      });
    }

    if (!window.AFRAME.components["block-camera"]) {
      AFRAME.registerComponent("block-camera", {
        schema: {
          cam: { type: "selector", default: "#cam" },
          minXAngle: { default: -30 },
          maxXAngle: { default: 30 },
          minYAngle: { default: -20 },
          maxYAngle: { default: 40 },
          active: { default: true },
        },
        init: function () {
          this.cam = this.data.cam;
          this.lookControls = this.data.cam.components["look-controls"];
          this.minXAngle = this.toRadians(this.data.minXAngle);
          this.minYAngle = this.toRadians(this.data.minYAngle);
          this.maxXAngle = this.toRadians(this.data.maxXAngle);
          this.maxYAngle = this.toRadians(this.data.maxYAngle);
          // console.log(this.minXAngle,this.minYAngle,this.maxXAngle,this.maxYAngle)
        },

        tick: function () {
          // console.log("cam", this.cam.components["camera"].camera.fov = 10)
          if (!this.data.active) return;
          // console.log("camPos", this.cam.object3D.rotation);
          // console.log("lookX", this.lookControls.pitchObject.rotation.x);
          // console.log("lookY", this.lookControls.yawObject.rotation.y);

          let rotation = this.cam.object3D.rotation;
          let pitch = this.lookControls.pitchObject.rotation;
          let yaw = this.lookControls.yawObject.rotation;
          let minXAngle = this.minXAngle;
          let maxXAngle = this.maxXAngle;
          let minYAngle = this.minYAngle;
          let maxYAngle = this.maxYAngle;
          // console.log(this.minYAngle)

          if (window.innerWidth < 500) {
            minYAngle -= this.toRadians(40);
            maxYAngle += this.toRadians(40);
          }
          // console.log(minYAngle)
          pitch.x = this.clamp(pitch.x, minXAngle, maxXAngle);
          yaw.y = this.clamp(yaw.y, minYAngle, maxYAngle);

          rotation.x = this.clamp(rotation.x, minXAngle, maxXAngle);
          rotation.y = this.clamp(rotation.y, minYAngle, maxYAngle);
        },

        clamp: (number, lower, upper) =>
          Math.min(Math.max(number, lower), upper),

        toRadians: (degrees) => (degrees * Math.PI) / 180,

        block: function (isActive) {
          let pitch = this.lookControls.pitchObject.rotation;
          let yaw = this.lookControls.yawObject.rotation;
          pitch.x = 0;
          yaw.y = 0;

          this.data.active = isActive;
        },
      });
    }
  }

  unregister() {
    delete window.AFRAME.components["block-camera"];
    delete window.AFRAME.components["video-controller"];
  }
}
