export function getXY(event) {
  const target = event.target;
  const rect = target.getBoundingClientRect();
  const x = event.changedTouches
    ? event.changedTouches[0].clientX
    : event.clientX;
  const y = event.changedTouches
    ? event.changedTouches[0].clientY
    : event.clientY;
  return {
    x: x - rect.left,
    y: y - rect.top,
  };
}

export const getPercentageInitialValue = (initialProgress) => {
  if (initialProgress === 90) {
    return 10;
  }

  if (initialProgress === 0) {
    return 100;
  }

  return 90;
};
