import React from 'react';
import './ContentPopup.scss';
import { CSSTransition } from 'react-transition-group';

export default class ContentPopup extends React.Component {
  static defaultProps = {
    isActive: false,
    videoIsActive: false,
    isMobile: false,
    type: 'default',
    containerWidth: 0,
    containerHeight: 0,
    onCloseVideoClick: () => {},
    onClosePopupClick: () => {},
    onPreviewClick: () => {},
    config: {
      youtubeSrc: '',
      imageSrc: '',
      title: '',
      text: '',
      position: '' // left or middle, or right
    }
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.audio = null;
  }

  render() {
    const { config, isActive, videoIsActive, onClosePopupClick } = this.props;
    return (
      <div className="sk-content-popup-container" onClick={onClosePopupClick}>
        <CSSTransition
          in={config && isActive && !videoIsActive}
          classNames="sk-content-popup"
          unmountOnExit
          timeout={300}
          onExited={this.playAudio()}
        >
          {this.getPopup()}
        </CSSTransition>
        <CSSTransition
          in={config && isActive && videoIsActive}
          classNames="sk-content-popup"
          unmountOnExit
          timeout={300}
        >
          {this.getFullscreenPopup()}
        </CSSTransition>
      </div>
    );
  }

  playAudio() {
    const {config, videoIsActive} = this.props;
    this.stopAudio();
    if (config.audioSrc && !videoIsActive) {
      this.audio = new Audio(config.audioSrc);
      this.audio.play();
    }
  }

  stopAudio() {
    if (this.audio) {
      this.audio.setAttribute("src", "");
      this.audio.pause();
      this.audio = null;
    }
  }

  getPopup() {
    const { config, type, isMobile, onClosePopupClick, onPreviewClick} = this.props;
    const classes = ['sk-content-popup'];
    if (isMobile) {
      classes.push('sk-content-popup--mobile');
    }
    if (type) {
      classes.push(`sk-content-popup--type-${type}`);
    }

    return (
      <div className={classes.join(' ')}>
        <div className={this.getCardClass()} onClick={e => e.stopPropagation()}>
          {config.imageSrc &&
            <div className="sk-content-popup__header-image-container">
              <img className="sk-content-popup__header-image" src={config.imageSrc} alt="" onClick={onPreviewClick}/>
            </div>
          }
          <div className="sk-content-popup__content">
            {config.title && <div className="sk-content-popup__title">{config.title}</div>}
            {config.text && <div className="sk-content-popup__text">{config.text}</div>}
          </div>
          {isMobile && <div className="sk-content-popup__close-button" onClick={onClosePopupClick} />}
        </div>
      </div>
    );
  }

  getFullscreenPopup() {
    const { onCloseVideoClick, config, isMobile } = this.props;
    return (
      <div className={`sk-content-popup sk-content-popup--full-screen ${isMobile ? 'sk-content-popup--mobile' : ''}`}>
        {config.videoSrc ? this.getVideoContent() : this.getYouTubeContent()}
        <div className="sk-content-popup__close-button" onClick={onCloseVideoClick} />
      </div>
    );
  }

  getVideoContent() {
    const { config } = this.props;
    return (<video className="sk-content-popup--video" src={config.videoSrc} loop={true} autoPlay={true} />);
  }

  getYouTubeContent() {
    const { config } = this.props;
    return (<iframe
      style={{ display: 'block' }}
      title="video"
      width="100%" height="100%" src={config.youtubeSrc + '?autoplay=1'} frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media;"
      allowFullScreen
    />);
  }

  getCardClass() {
    const { isMobile, containerWidth, containerHeight } = this.props;
    const isHorizontal = containerWidth > containerHeight;
    return `sk-content-popup__card
      ${isMobile ? 'sk-content-popup__card--mobile' : `sk-content-popup__card--position_${this.props.config.position}`}
      ${isMobile && isHorizontal ? 'sk-content-popup__card--horizontal': ''}
      `;
  }
}
