import React from "react";
import "./Exterior.scss";
import ExteriorService from "../../services/Exterior";
import { EVENTS as ExteriorEvents } from "../../services/Exterior";
import { InfoSpotCollection } from "../InfoSpot";
import { sendGoogleData } from "../../services/ga";

export default class Exterior extends React.Component {
  static defaultProps = {
    config: {},
    spotConfig: {},
    isMobile: false,
    isTouch: false,
  };

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.wrapperRef = React.createRef();
    this.state = {
      canvasSize: {
        width: 0,
        height: 0,
      },
      frame: -1,
    };
  }

  render() {
    const { config, isMobile } = this.props;
    return (
      <div className={`sk-exterior ${isMobile ? "sk-exterior--mobile" : ""}`}>
        <div
          className="sk-exterior__prev-frame"
          onClick={this.tweenPrevFrame.bind(this)}
        />

        <div className="sk-exterior-wrapper">
          <div
            className="sk-exterior-wrapper__top"
            style={{ backgroundColor: this.props.config.topColor }}
          />
          <div
            ref={this.wrapperRef}
            className="sk-exterior-wrapper__center"
            style={{
              height: this.state.canvasSize.height,
              minHeight: this.state.canvasSize.height,
              backgroundImage: `url(${config.backgroundUrl})`,
            }}
          >
            <div className="sk-exterior-canvas-wrapper">
              <canvas
                ref={this.canvasRef}
                id="sk-exterior-canvas"
                className="sk-exterior-wrapper__canvas"
              />
              <InfoSpotCollection
                spots={config.spots.map((spot) => ({
                  ...spot,
                  isShow: spot.frames.includes(this.state.frame),
                }))}
                isMobile={this.props.isMobile}
                isTouch={this.props.isTouch}
                config={this.props.spotConfig}
                containerWidth={this.props.containerWidth}
                containerHeight={this.props.containerHeight}
                onActivateSpot={this.activateSpot.bind(this)}
                onDeactivateSpot={this.deactivateSpot.bind(this)}
              />
            </div>
          </div>
          <div
            className="sk-exterior-wrapper__bottom"
            style={{ backgroundColor: this.props.config.bottomColor }}
          />
        </div>

        <div
          className="sk-exterior__next-frame"
          onClick={this.tweenNextFrame.bind(this)}
        />
      </div>
    );
  }

  tweenPrevFrame() {
    sendGoogleData(
      "Skoda_360",
      "arrow_icon_click",
      undefined,
      undefined,
      false
    );
    this.service.tweenToPrevFrame();
  }

  tweenNextFrame() {
    sendGoogleData(
      "Skoda_360",
      "arrow_icon_click",
      undefined,
      undefined,
      false
    );
    this.service.tweenToNextFrame();
  }

  componentDidMount() {
    const { onStartLoading, onStopLoading, config } = this.props;
    onStartLoading();
    this.service = new ExteriorService({
      ...config,
      node: this.canvasRef.current,
      alternateFramesKeys: this.getAllAlternateFrames(),
    });
    this.setCanvasSize(this.service.size);
    this.service
      .load()
      .then(() => this.service.drawCurrentFrame())
      .finally(() => {
        onStopLoading();
        sendGoogleData(
          "Skoda_360",
          "loaded_exterior",
          undefined,
          this.props.config.name,
          true
        );
      });
    this.service.addEventListener(
      ExteriorEvents.RESIZE,
      this.setCanvasSize.bind(this)
    );
    this.service.addEventListener(
      ExteriorEvents.DRAW_FRAME,
      this.onDrawFrame.bind(this)
    );
  }

  getAllAlternateFrames() {
    const frames = [];
    this.props.config.spots.forEach(
      (spot) => spot.alternateFrames && frames.push(...spot.alternateFrames)
    );
    return frames;
  }

  activateSpot(spot) {
    if (spot && spot.alternateFrames) {
      this.service.drawAlternateFrame(spot.alternateFrames[0]);
    }
  }

  deactivateSpot(spot) {
    if (spot) {
      this.service.drawCurrentFrame(true);
    }
  }

  onDrawFrame({ frame }) {
    this.setState({ frame });
  }

  setCanvasSize({ width, height } = {}) {
    this.setState({
      canvasSize: {
        width: width,
        height: height,
      },
    });
  }

  componentWillUnmount() {
    this.service && this.service.dispose();
  }
}
