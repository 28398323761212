import './Interior.scss'
import React from 'react'
import InteriorService from '../../services/Interior'
import { EVENTS as InteriorEvents } from '../../services/Interior'
import { InfoSpotCollection } from '../InfoSpot'

export default class Interior extends React.Component {
  static defaultProps = {
    config: {},
    spotConfig: {},
    isMobile: false,
    isTouch: false,
  }

  constructor(props) {
    super(props)
    this.canvasRef = React.createRef()
    this.state = {
      infoSpots: this.props.config.spots.map((spot) => ({
        ...spot,
        position: [0, 0],
        isShow: false,
      })),
    }
  }

  render() {
    return (
      <div className='sk-interior'>
        <div className='sk-interior__canvas-container' ref={this.canvasRef} />
        <InfoSpotCollection
          spots={this.state.infoSpots.map((spot) => ({ ...spot }))}
          positionType='px'
          autoPosition={true}
          isMobile={this.props.isMobile}
          isTouch={this.props.isTouch}
          config={this.props.spotConfig}
          containerWidth={this.props.containerWidth}
          containerHeight={this.props.containerHeight}
          onActivateSpot={this.activateSpot.bind(this)}
          onDeactivateSpot={this.deactivateSpot.bind(this)}
          container={this.canvasRef.current}
        />
      </div>
    )
  }

  activateSpot(spot) {
    if (spot && spot.alternateImages && spot.alternateImages.length > 0) {
      this.service.setPanorama(spot.alternateImages[0])
    }
  }

  deactivateSpot(spot) {
    if (spot) {
      this.service.setPanorama(0)
    }
  }

  onChangeCameraView() {
    const spotSize = this.props.spotConfig.size
    this.setState((state) => ({
      infoSpots: state.infoSpots.map((spot, index) => {
        const screenPosition = this.service.getScreenVector(
          state.infoSpots[index].worldPosition
        )
        const left = Math.round(screenPosition.x)
        const top = Math.round(screenPosition.y)
        let isShow =
          left > spotSize &&
          left + spotSize < this.canvasRef.current.clientWidth &&
          top > spotSize &&
          top + spotSize < this.canvasRef.current.clientHeight
        return { ...spot, position: [left, top], isShow }
      }),
    }))
  }

  componentDidMount() {
    const { onStartLoading, onStopLoading, config } = this.props
    onStartLoading()
    this.service = new InteriorService(
      this.canvasRef.current,
      config.panoramaImages
    )
    this.service
      .load()
      .then(() => console.log('Interior load'))
      // задержка для плавного выхода, т.к. он там еще что то делает после резолва
      .finally(() => setTimeout(() => onStopLoading(), 1000))
    this.service.addEventListener(
      InteriorEvents.CHANGE_VIEW,
      this.onChangeCameraView.bind(this)
    )
  }

  componentWillUnmount() {
    this.service.dispose()
  }
}
