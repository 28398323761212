/* eslint-disable no-restricted-globals */

export const sendGoogleData = (
  eventCategory,
  eventAction,
  timeSlot,
  eventLabel,
  eventNoInteract,
  type = "iframe"
) => {
  try {
    if (window.location !== window.parent.location) {
      if (typeof parent.postMessage != "undefined") {
        const message = {
          type: type,
          event: "custom.postMessage",
          eventCategory,
          eventAction: eventAction,
          eventLabel: eventLabel,
          timeslot: timeSlot,
          eventNoInteract: eventNoInteract === true,
        };
        // Convent message into a string
        const messageJSON = JSON.stringify(message);
        //Send message to parent
        parent.postMessage(messageJSON, "*");
      }
    } else {
      window.dataLayer_kdx = window.dataLayer_kdx || [];
      window.dataLayer_kdx.push({
        event: type === "iframe_activity" ? "activity" : "send_ga",
        eventAction: eventAction,
        eventLabel: eventLabel,
        timeslot: timeSlot,
        eventNoInteract: eventNoInteract === true,
      });
    }
  } catch (err) {
    console.log(err);
  }
};
