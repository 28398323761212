const HTML = `

    <div class='instruction-panel'>
      <div class="instructions">
        <div class="header-part unselectable"></div>
        <div class="main-part unselectable">
          <div class="parts"><div class="globe-container"><img id="globe" src=""></div></div>
          <div class="parts but">
            <button id="fakeOutside" class="main-buttons" disabled><img src=""><span>С улицы</span></button>
            <button id="fakeInside" class="main-buttons" disabled><img src=""><span>Из салона</span></button>
            <img id="hand" src="">
          </div>
          <div class="parts">
            <div class="button-text">
              <button id="fakeDrone" class="main-buttons small-buttons" disabled ><img src="" ></button>
              <span>квадрокоптера</span>
            </div>
            <div class="button-text">
              <button id="fakeObstacle" class="main-buttons small-buttons" disabled><img src="" ></button>
              <span>препятствий</span>
            </div>
            <div class="button-text">
              <button id="fakePoint" class="main-buttons small-buttons" disabled><img src="" ></button>
              <span>точки интереса</span>
            </div>
          </div>
        </div>
        <div class="text-part unselectable"></div>

      </div>
      <button id="instructionButton" class="main-buttons active"></button>
    </div>



    <div class="loaderv-conatiner">
      <div class="persent-text">0%</div>
      <!-- <video id="loaderVideo" preload="auto" loop crossorigin="anonymous" webkit-playsinline playsinline src="https://skoda-cdn.riftman.ru/1111content/Kodiaq/kodiaq_loader.mp4"></video> -->
      <video id="loaderVideo" preload="auto" loop crossorigin="anonymous" webkit-playsinline playsinline src=""></video>
    </div>

    <div class="main-menu">
      <div class="offroad-menu">
        <button id="pointButton" class="main-buttons small-buttons" disabled data-tooltip="Точка интереса &#xa; &#34;Сдвоенный боковой уклон&#34;"><img src="" ></button>
        <button id="outsideButton" class="main-buttons"><img src=""><span>С улицы</span></button>
        <button id="pauseButton" class="main-buttons small-buttons"><img src="" ></button>
        <button id="insideButton" class="main-buttons active"><img src=""><span>Из салона</span></button>
        <button id="droneButton" class="main-buttons small-buttons" disabled data-tooltip="Посмотреть с дрона"><img src=""></button>
      </div>
    </div>
    <div class="video-panel">
      <div class="video-container">   
        <video id="video4" crossorigin="anonymous" webkit-playsinline playsinline src=""></video>
        <button id="closeVideoButton" class=""><img src="images/Close.svg"></button>
      </div>
    </div>

    <div div class="finish-panel">
      <div class="popup-window">
        <button id="closeOffroadButton" class=""><img src="images/CloseGreen.svg"></button>
        <p class="bold-p">Спасибо за прохождение виртуального внедорожного тест-драйва!</p>
        <p class="normal-p">Приглашаем вас посетить <br/>официального дилера ŠKODA</p>
        <button id="finishButton" class="main-buttons active" type="button">Записаться на тест-драйв</button>
        <button id="restartButton" class="main-buttons" type="button">Пройти еще раз</button>
      </div>
    </div>

    <div class="low-speed-panel">
      <div class="popup-window">
        <p class="bold-p">У Вас низкая скорость интернет соединения. Прохождение виртуального тест-драйва может быть затруднено!</p>
        <p class="normal-p">Для продолжения попробуйте подключиться к Wi-Fi или выбрать более скоростной тариф Вашего оператора связи. Рекомендуемая скорость: 20 Mbps</p>
        <button id="lowSpeedButton" class="main-buttons active" type="button">ОК, Понятно</button>
      </div>
    </div>

    <div class="up-pannel">
      <div class="close-panel">
        <button id="closeButton" class="" type="button"><img src="images/arrow.svg"><span>Завершить</span></button>
      </div>
      <div class="resolution-panel">
        <button id="resolutionButton" class="main-buttons small-flat-buttons" type="button"></button>

      </div>

      <div class="sound-panel">
        <button id="soundButton" class="sound-button" type="button"><img src="images/MoreVolume.svg"></button>
      </div>
    </div>

    <div id="spinner"  class="my-spinner uk-icon uk-spinner">
      <svg width="60" height="60" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" data-svg="spinner">
        <circle fill="none" stroke="#4BA82E" cx="15" cy="15" r="14"></circle>
      </svg>
      <span class="my-spinner-label">Загрузка</span>
    </div>

    <a-scene device-orientation-permission-ui="enabled:false;cancelButtonText:Отмена; allowButtonText:Разрешить; denyButtonText:Запретить; httpsMessage:Зайдите на сайт через HTTPS, чтобы получить доступ к сенсорам; deviceMotionMessage: Сайт требует доступ к вашим сенсорам;" block-camera="minYAngle:-40;maxYAngle:40;minXAngle:-20;maxXAngle:35" video-controller="linkdata:true" vr-mode-ui="enabled: false">
        <a-assets>
            <video id="video1" crossorigin="anonymous" webkit-playsinline playsinline src=""> </video>
            <video id="video2" crossorigin="anonymous" webkit-playsinline playsinline src=""> </video>
            <!-- <video id="video2" crossorigin="anonymous" webkit-playsinline playsinline src=""></video>  -->
            <!-- <video id="video3" crossorigin="anonymous" webkit-playsinline playsinline src=""></video>  -->
        </a-assets>

        <a-videosphere  rotation="0 -90 0" id="vsphere1" src="#video1" ></a-videosphere> 
        <!-- <a-videosphere  rotation="0 -90 0" id="vsphere2" src="#video2" visible="false"></a-videosphere>  -->
        <!-- <a-videosphere  rotation="0 -90 0" id="vsphere3" src="#video3" visible="false"></a-videosphere>  -->
        
         <a-plane id="playb" transparent="true" class="interractible" position="0 1.5 -2"  width="1" height="1" scale="0 0 0" color="black">
            <a-plane class="interractible" width="1" height="1" position="0 0 0.01" color="black">
              <a-videosphere color="black" radius="10 10 10"></a-videosphere>    
            </a-plane>
          </a-plane>
          
          <a-entity id="cam" camera="fov:70" look-controls position="0 1.8 0" cursor="rayOrigin: mouse" raycaster="objects: .interractible">

    </a-scene>
`;
export default HTML;
