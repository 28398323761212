class LoadingProgress {
  progress = 0;
  updateStateFunction;

  setProgress(progress) {
    this.progress = progress;
    this.updateStateFunction({ progress: progress, isLoading: true });
  }

  getProgress() {
    return this.progress;
  }

  setUpdateStateFunction(func) {
    this.updateStateFunction = func;
  }

  clearProgress() {
    this.progress = 0;
    this.updateStateFunction({ progress: 0, isLoading: false });
  }
}

export const loadingProgress = new LoadingProgress();
