import axios from 'axios';

const defaultConfig = {
  loader: {
    videoUrl: ''
  },
  spot: {
    size: 34,
    mobileSizePercent: 0.75
  },
  offroad: {

  },
  interior: {
    panoramaImages: [
      '',
      '',
      ''
    ],
    spots: [{
      id: 'pin13',
      worldPosition: [3774.97, -2532.65, 2067.19],
      popupConfig: {
        imageSrc: '',
        title: 'До девяти подушек безопасности',
        text: 'Оптимизация работы пассивных систем безопасности, таких как подушки безопасности, преднатяжители и ограничители натяжения ремней безопасности, значительно снижает риск травмы. В SKODA KODIAQ  доступно до 9 подушек безопасности.',
        position: 'right'
      }
    }]
  },
  exterior: {
    topColor: '#64605b',
    bottomColor: '#B8B5B1',
    backgroundUrl: "",
    imagesURLPattern: "",
    spots: [{
      id: 'pin7',
      position: [27, 62],
      frames: [33],
      popupConfig: {
        imageSrc: '',
        title: 'Адаптивный круиз-контроль (АСС)',
        text: 'Система поддерживает дистанцию до движущегося впереди транспорта замедляя или ускоряя автомобиль в зависимости от скорости движущегося впереди транспорта или заданного водителем расстояния. Помимо предустановки скорости, водитель может также устанавливать дистанцию и регулировать интенсивность работы системы',
        position: 'right'
      }
    }]
  }
};

const CONFIG_DEFAULT_URL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ?
  'config/Kodiaq.json' :
  '';

const urlParams = new URLSearchParams(window.location.search);
const urlFromQuery = urlParams.get('configUrl');

const modelUrl = "https://virtual-test-drive.skoda-avto.ru/admin/api/api/model";

console.log(window.location);

const CONFIG_URL = urlFromQuery || CONFIG_DEFAULT_URL;

export default class Config {
  constructor() {
    this._config = {
      ...defaultConfig
    }
  }

  get config() {
    return this._config;
  }

  load() {
    var {
      pathname
    } = window.location;

    var promiseModel = new Promise(function (resolve) {
      resolve();
    });

    var model = pathname.split('/').filter(Boolean);

    if (model.length === 1) {
      promiseModel = axios.get(`${modelUrl}/${model[0]}`);
    }

    var promiseUrl = axios.get(`${CONFIG_URL}?&timestamp=${new Date().getTime()}`);

    return promiseModel.then(
      (response) => {
        if (response && response.data) {
          this._config = Object.assign(this.config, response.data);
        } else {
          return promiseUrl.then(
            (responseData) => {
              this._config = Object.assign(this.config, responseData.data);
            },
            (error) => {
              console.error(error);
            }
          );
        }
      },
      (error) => {
        console.error(error);
        return promiseUrl.then(
            (responseData) => {
              this._config = Object.assign(this.config, responseData.data);
            },
            (error) => {
              console.error(error);
            }
          );
      }
    )
  }

};