import React from "react";
import "./nav-bar.scss";
import { sendGoogleData } from "../../services/ga";

export default class NavBar extends React.Component {
  render() {
    return (
      <nav className="sk-nav-bar">
        <ul className="sk-nav-bar__list" onClick={this.onSelectItem.bind(this)}>
          {this.getItems()}
        </ul>
      </nav>
    );
  }

  onSelectItem(e) {
    e.preventDefault();
    if (e.target.classList.contains("sk-nav-bar__item")) {
      const id = e.target.getAttribute("data-key");
      const index = this.props.items.indexOf(
        this.props.items.find((item) => item.id === id)
      );

      this.props.onChangeMenu(index);
      sendGoogleData(
        "Skoda_360",
        "view_select",
        undefined,
        this.props.items[index].id,
        false
      );
    }
  }

  getItems() {
    return this.props.items.map((item) => {
      const classes = ["sk-nav-bar__item"];

      if (item.active) {
        classes.push("sk-nav-bar__item--active");
      }

      if (item.disable) {
        classes.push("sk-nav-bar__item--disable");
      }

      return (
        <li
          className={classes.join(" ")}
          data-key={item.id.toString()}
          key={item.id.toString()}
        >
          {item.title}
        </li>
      );
    });
  }
}
