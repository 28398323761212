import * as React from "react";
import InfoSpot from "../InfoSpot";
import ContentPopup from "../ContentPopup";
import { sendGoogleData } from "../../services/ga";

export default class InfoSpotCollection extends React.Component {
  static defaultProps = {
    spots: [],
    config: {},
    positionType: "%",
    container: null,
    autoPosition: false,
    isMobile: false,
    isTouch: false,
    containerWidth: 0,
    containerHeight: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      viewedSpots: {},
      activeSpot: undefined,
      activePopupConfig: undefined,
      contentPopupIsActive: false,
      contentPopupVideoIsActive: false,
      onActivateSpot: () => null,
      onDeactivateSpot: () => null,
    };
  }

  render() {
    const activePopupConfig =
      this.state.activePopupConfig && this.props.autoPosition
        ? {
            ...this.state.activePopupConfig,
            position: this.getActiveSpotPosition(),
          }
        : this.state.activePopupConfig;
    return (
      <React.Fragment>
        {this.getSpots()}
        <ContentPopup
          config={activePopupConfig}
          type={this.getSpotType(this.state.activeSpot)}
          isMobile={this.props.isMobile}
          containerWidth={this.props.containerWidth}
          containerHeight={this.props.containerHeight}
          isActive={this.state.contentPopupIsActive}
          videoIsActive={this.state.contentPopupVideoIsActive}
          onCloseVideoClick={this.closeActiveVideo.bind(this)}
          onClosePopupClick={this.closeActivePopup.bind(this)}
          onPreviewClick={this.onPreviewClick.bind(this)}
        />
      </React.Fragment>
    );
  }

  getActiveSpotPosition() {
    if (
      !this.state.activeSpot ||
      !this.state.activeSpot.position ||
      !this.props.container
    ) {
      return "";
    }
    const canvasWidth = this.props.container.clientWidth;
    const center = canvasWidth / 2;
    const x = this.state.activeSpot.position[0];
    const spotSize = 360; // TODO: to config
    const pinSize = this.props.config.size;
    return Math.abs(x - center) > spotSize / 2 + pinSize
      ? "middle"
      : center < x
      ? "left"
      : "right";
  }

  isVideoPopup(config) {
    return config && (config.youtubeSrc || config.videoSrc);
  }

  getSpotType(spot) {
    return spot && this.isVideoPopup(spot.popupConfig) ? "video" : undefined;
  }

  getSpots() {
    return this.props.spots.map((spot) => {
      return (
        <InfoSpot
          key={spot.id}
          id={spot.id}
          config={this.props.config}
          type={this.getSpotType(spot)}
          isViewed={this.state.viewedSpots[spot.id]}
          isShow={spot.isShow}
          isMobile={this.props.isMobile}
          position={spot.position}
          positionType={this.props.positionType}
          onClick={this.onSpotClick.bind(this)}
          //onTouchStart={this.onSpotClick.bind(this)}
          onMouseEnter={this.onSpotHover.bind(this)}
          onMouseLeave={this.onSpotLeave.bind(this)}
        />
      );
    });
  }

  findSpotById(id) {
    return this.props.spots.find((spot) => spot.id === id);
  }

  showOne(id) {
    this.props.spots.forEach((spot) => (spot.isShow = spot.id === id));
  }

  showAll() {
    this.props.spots.forEach((spot) => (spot.isShow = true));
  }

  closeActiveVideo() {
    if (this.props.isMobile) {
      this.setState({
        contentPopupVideoIsActive: false,
      });
    } else {
      this.setState({
        contentPopupIsActive: false,
        activePopupConfig: undefined,
        contentPopupVideoIsActive: false,
      });
    }
  }

  closeActivePopup() {
    this.deactivateSpot(this.state.activeSpot.id);
  }

  onSpotClick(e, id) {
    let isActivePopup = this.state.contentPopupIsActive;
    if (this.state.activeSpot && id !== this.state.activeSpot.id) {
      this.deactivateSpot(this.state.activeSpot.id);
      isActivePopup = false;
    }
    if ((this.props.isTouch || this.props.isMobile) && !isActivePopup) {
      this.activateSpot(id);
    } else {
      this.openVideo();
    }
  }

  openVideo() {
    if (this.isVideoPopup(this.state.activePopupConfig)) {
      this.setState({
        contentPopupVideoIsActive: true,
      });
    }
  }

  onPreviewClick() {
    this.openVideo();
  }

  onSpotHover(e, id) {
    if (!this.props.isTouch && !this.props.isMobile) {
      this.activateSpot(id);
      sendGoogleData(
        "Skoda_360",
        "plus_icon_hover",
        undefined,
        undefined,
        false
      );
    }
  }

  onSpotLeave(e, id) {
    if (!this.props.isTouch && !this.props.isMobile) {
      this.deactivateSpot(id);
    }
  }

  activateSpot(id) {
    const spot = this.findSpotById(id);
    this.setState({
      activeSpot: spot,
      contentPopupIsActive: true,
      activePopupConfig: spot.popupConfig,
      contentPopupVideoIsActive: false,
    });
    this.props.onActivateSpot && this.props.onActivateSpot(spot);
  }

  deactivateSpot(id) {
    const spot = this.findSpotById(id);
    if (!this.state.contentPopupVideoIsActive) {
      this.setState({
        activeSpot: undefined,
        contentPopupIsActive: false,
        activePopupConfig: undefined,
        contentPopupVideoIsActive: false,
      });
    }
    this.setState((state) => ({
      viewedSpots: { ...state.viewedSpots, [id]: true },
    }));
    this.props.onDeactivateSpot && this.props.onDeactivateSpot(spot);
  }
}
