import "./off-road.css";
import React from "react";
import html from "./off-roadHTML";
import OffRoadService from "../../services/Offroad";

const rusModelName = {
  Karoq: "Карок",
  Kodiaq: "Кодиак",
};

const getDocumentTitle = (modelName = "") =>
  `Тест-драйв ŠKODA ${modelName.toUpperCase()} – Виртуальный тест драйв автомобиля Шкода на официальном сайте в России.`;

const getDocumentDescription = (modelName = "") =>
  `Виртуальный тест драйв автомобиля Skoda ${modelName}. Новая возможность пройти тест драйв не выходя из дома: доступен вид из салона, с улицы, с квадрокоптера. Также вы можете посмотреть прохождение препятствий на участках дорог. Протестируйте Шкода ${rusModelName[modelName]} прямо сейчас, на официальном сайте ŠKODA в России.`;

export default class OffRoad extends React.Component {
  static defaultProps = {
    config: {},
    isMobile: false,
    onStartLoading: () => {},
    onStopLoading: () => {},
    onCloseOffroad: () => {},
  };

  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
  }

  render() {
    return (
      <>
        {this.props.isOffRoadRender && (
          <div
            ref={this.wrapperRef}
            className="sk-off-road-container"
            dangerouslySetInnerHTML={{ __html: html }}
          ></div>
        )}
      </>
    );
  }

  componentDidMount() {
    const {
      onStartLoading,
      config,
      onStopLoading,
      loaderUrl,
      cspeed,
      onlyvirtualtestdrive,
      modelName,
    } = this.props;
    onStartLoading();
    document.title = getDocumentTitle(modelName);
    const description = document.querySelector("meta[name='description']");
    description.setAttribute("content", getDocumentDescription(modelName));

    this.service = new OffRoadService();
    this.service.register(
      loaderUrl,
      cspeed,
      onlyvirtualtestdrive,
      modelName,
      config.link
    );
    onStopLoading();

    setTimeout(() => {
      this.props.renderOffRoad(true);
    }, 0);
  }

  componentDidUpdate(prevProps) {
    if (
      !this.wrapperRef.current ||
      this.props.isOffRoadRender === prevProps.isOffRoadRender
    ) {
      return;
    }

    this.wrapperRef.current.addEventListener(
      "closeOffroad",
      this.onClose.bind(this)
    );
  }

  onClose() {
    this.props.onCloseOffroad();
  }

  componentWillUnmount() {
    if (this.service) {
      this.service.unregister();
    }
    this.props.renderOffRoad(false);
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
  }
}
