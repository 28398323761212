import { Viewer, ImagePanorama } from "panolens";
import { Vector3, EventDispatcher, Matrix4 } from "three";
import { loadingProgress } from "./LoadingProgress";
import { sendGoogleData } from "../services/ga";

export const EVENTS = {
  CHANGE_VIEW: "change-view",
};

const START_LOOKAT = new Vector3(4850, -1236.07, 0);

export default class Interior extends EventDispatcher {
  constructor(node, imagePaths) {
    super();
    this.node = node;
    this.imagePaths = imagePaths;

    this.viewer = new Viewer({
      container: this.node,
      controlBar: false,
      output: "console",
    });
    this.viewer.getControl().noZoom = true;
    this.viewer.getControl().momentumDampingFactor = 0.0;

    this._update = this.update.bind(this);
    this.viewer.addUpdateCallback(this._update);
    this.camera = this.viewer.getCamera();
    this.cameraFrustum = this.viewer.cameraFrustum;
    this.cameraViewProjectionMatrix = new Matrix4();
    this.lastLookAt = new Vector3(0, 0, 0);

    this._onResize = this.onResize.bind(this);
    window.addEventListener("resize", this._onResize);
  }

  setProgress = (p) => {
    loadingProgress.setProgress(Math.floor(p));
  };

  load() {
    this.panorams = [];
    const promises = [];
    this.imagePaths.forEach((img) => {
      const panorama = new ImagePanorama(img);
      panorama.animationDuration = 0;
      this.panorams.push(panorama);
      promises.push(
        new Promise((resolve) => {
          panorama.addEventListener("load", () => {
            resolve(panorama);
          });
          panorama.load();
        })
      );
    });

    let progress = loadingProgress.getProgress();

    promises.forEach((promise) => {
      promise.then(() => {
        progress++;
        this.setProgress((progress * 100) / promises.length);
      });
    });

    return Promise.all(promises)
      .then(() => {
        // TODO: remove hacks
        this.viewer.add(...this.panorams);
        this.viewer.tweenControlCenter(START_LOOKAT, 0);
        this.panorams.forEach((pano) => pano.fadeOut(50));
        this.panorams[0].fadeIn(0);
        return new Promise((resolve) => setTimeout(resolve, 400));
      })
      .finally(() => {
        loadingProgress.clearProgress();
        sendGoogleData(
          "Skoda_360",
          "loaded_interior",
          undefined,
          this.props.config.name,
          true
        );
      });
  }

  setPanorama(index) {
    this.viewer.setPanorama(this.panorams[index]);
  }

  update() {
    const lookAt = new Vector3();
    this.viewer.getCamera().getWorldDirection(lookAt);
    if (lookAt.clone().sub(this.lastLookAt).length() > 0.000001) {
      this.dispatchEvent({ type: EVENTS.CHANGE_VIEW });
      this.lastLookAt = lookAt;
    }
  }

  onResize() {
    this.dispatchEvent({ type: EVENTS.CHANGE_VIEW });
  }

  checkPointInViewport(point) {
    this.camera.matrixWorldInverse.getInverse(this.camera.matrixWorld);
    this.cameraViewProjectionMatrix.multiplyMatrices(
      this.camera.projectionMatrix,
      this.camera.matrixWorldInverse
    );
    this.cameraFrustum.setFromMatrix(this.cameraViewProjectionMatrix);
    return this.cameraFrustum.containsPoint(point);
  }

  getScreenVector(position) {
    const vec = new Vector3(-position[0], position[1], position[2]);
    if (!this.checkPointInViewport(vec)) {
      return new Vector3(-1000, -1000, 0);
    }
    return this.viewer.getScreenVector(vec);
  }

  dispose() {
    window.removeEventListener("resize", this._onResize);
    this.panorams && this.panorams.forEach((pano) => pano.dispose());
    this.panorama && this.panorama.dispose();
    this.viewer.removeUpdateCallback(this._update);
    this.viewer.dispose();
  }
}
