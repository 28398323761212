import { getXY } from "./Utils";
import { EventDispatcher } from "three";
import TWEEN from "tween.js";
import { loadingProgress } from "./LoadingProgress";
import { getPercentageInitialValue } from "./Utils";

export const EVENTS = {
  RESIZE: "resize",
  DRAW_FRAME: "draw-frame",
};

const SCALE_COUNT = 2;
const ENABLE_SCALE = false;

export default class Exterior extends EventDispatcher {
  constructor({
    node,
    imagesURLPattern,
    startFrame = 0,
    endFrame = 89,
    currentFrame = 0,
    keyFrames = [],
    alternateFramesKeys = [],
    frameWidth = 1280,
    frameHeight = 720,
    rotateSpeed = 40,
  }) {
    super();
    this.node = node;
    this.nativeWidth = 0;
    this.nativeHeight = 0;
    this.rotateSpeed = rotateSpeed;
    this.pattern = imagesURLPattern;
    this.startFrame = startFrame;
    this.endFrame = endFrame;
    this.frameWidth = frameWidth;
    this.frameHeight = frameHeight;
    this.currentFrame = currentFrame;
    this.lastDrawFrame = -1;
    this.keyFrames = keyFrames;
    this.alternateFramesKeys = alternateFramesKeys;

    this.frames = [];
    this.frames.push([]);
    for (let i = 1; i <= SCALE_COUNT; i++) {
      this.frames.push([]);
    }

    this.alternateFrames = [];
    this.alternateFrames.push([]);
    for (let i = 1; i <= SCALE_COUNT; i++) {
      this.alternateFrames.push([]);
    }

    this.isLoaded = false;
    this.startMove = false;
    this.moveDirection = 0;
    this.moveX = 0;
    this.ctx = node.getContext("2d");

    this.initCanvas();
    this.initEvents();

    this.tweenFrame = new TWEEN.Tween();
  }

  initCanvas() {
    this.calcCanvasSize();
  }

  initEvents() {
    this._onResize = this.onResize.bind(this);
    this._onMove = this.onMove.bind(this);
    this._onDown = this.onDown.bind(this);
    this._onUp = this.onUp.bind(this);

    this.node.addEventListener("mousemove", this._onMove);
    this.node.addEventListener("mousedown", this._onDown);
    this.node.addEventListener("mouseup", this._onUp);

    this.node.addEventListener("touchmove", this._onMove);
    this.node.addEventListener("touchstart", this._onDown);
    this.node.addEventListener("touchend", this._onUp);
    this.node.addEventListener("touchcancel", this._onUp);

    window.addEventListener("resize", this._onResize);
  }

  stopFrameTweens() {
    this.tweenFrame.stop();
  }

  tweenToFrame(frame) {
    this.stopFrameTweens();
    const data = { index: this.currentFrame };
    const duration =
      (Math.abs(this.currentFrame - frame) * 1000) / this.rotateSpeed;
    this.tweenFrame = new TWEEN.Tween(data)
      .to({ index: frame }, duration)
      .onUpdate(() => {
        this.setFrameIndex(data.index);
        this.drawCurrentFrame();
      })
      .start();
  }

  tweenToPrevFrame() {
    const nextFrame =
      this.keyFrames.find((frame) => this.currentFrame < frame) ||
      this.endFrame + this.keyFrames[0];
    this.tweenToFrame(nextFrame);
  }

  tweenToNextFrame() {
    const nextFrame =
      [...this.keyFrames]
        .reverse()
        .find((frame) => this.currentFrame > frame) ||
      [...this.keyFrames].pop() - this.endFrame;
    this.tweenToFrame(nextFrame);
  }

  get nodeWidth() {
    return this.node.getBoundingClientRect().width;
  }

  get size() {
    return { width: this.nativeWidth, height: this.nativeHeight };
  }

  getUrlForFrame(index) {
    return this.pattern.replace(
      "{frame}",
      typeof index === "string" ? index : index.toString().padStart(2, "0")
    );
  }

  onResize() {
    this.calcCanvasSize();
  }

  calcCanvasSize() {
    const aspect = this.frameWidth / this.frameHeight;
    const rect = this.node.getBoundingClientRect();
    const dpr = window.devicePixelRatio || 1;
    this.nativeWidth = rect.width;
    this.nativeHeight = this.nativeWidth / aspect;
    this.node.width = this.nativeWidth * dpr;
    this.node.height = this.nativeHeight * dpr;
    this.dispatchEvent({
      type: EVENTS.RESIZE,
      width: this.nativeWidth,
      height: this.nativeHeight,
    });
    if (this.isLoaded) {
      this.drawCurrentFrame(true);
    }
  }

  onMove(e) {
    e.preventDefault();
    if (!this.startMove) {
      return;
    }

    const positionX = getXY(e).x;
    const diffPersent = (this.moveX - positionX) / this.nodeWidth;
    const nextFrame = Math.round(
      (this.endFrame - this.startFrame) * diffPersent
    );
    if (nextFrame) {
      this.stopFrameTweens();
      this.addFrameIndex(nextFrame);
      this.drawCurrentFrame();
      this.moveDirection = diffPersent;
      this.moveX = positionX;
    }
  }

  onDown(e) {
    e.preventDefault();
    this.startMove = true;
    this.moveX = getXY(e).x;
  }

  onUp(e) {
    e.preventDefault();
    this.startMove = false;
    this.moveDirection > 0 ? this.tweenToPrevFrame() : this.tweenToNextFrame();
  }

  _downloadImage(url) {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve(img);
      };
      img.onerror = () => {
        resolve(null);
      };
      img.src = url;
    });
  }

  buildScaledImages(img, index, frames) {
    if (ENABLE_SCALE) {
      let scaleImage = this._scaleImage(img, 0.5);
      for (let j = 1; j <= SCALE_COUNT; j++) {
        frames[j].push({ index: index, image: scaleImage });
        scaleImage = this._scaleImage(scaleImage, 0.5);
      }
    }
  }

  setProgress = (p) => {
    loadingProgress.setProgress(Math.floor(p));
  };

  loadFrames(frames, keys, noProgress) {
    console.log(frames, keys);
    const promises = keys.map((i) => {
      return this._downloadImage(this.getUrlForFrame(i)).then((img) => {
        frames[0].push({ index: i, image: img });
        this.buildScaledImages(img, i, frames);
      });
    });

    const initialProgress = loadingProgress.getProgress();
    let progress = loadingProgress.getProgress();

    if (!noProgress) {
      promises.forEach((promise) => {
        promise.then(() => {
          progress++;
          this.setProgress(
            (progress * getPercentageInitialValue(initialProgress)) /
              promises.length
          );
        });
      });
    }

    return Promise.all(promises).then(() => {
      frames.forEach((frames) =>
        frames.sort((a, b) => {
          return a.index - b.index;
        })
      );
    });
  }

  load() {
    return this.loadFrames(
      this.frames,
      [...Array(this.endFrame + 1).keys()].map((i) => i + this.startFrame)
    )
      .then(() =>
        this.loadFrames(this.alternateFrames, this.alternateFramesKeys, true)
      )
      .then(() => {
        this.isLoaded = true;
        loadingProgress.clearProgress();
      });
  }

  setFrameIndex(index) {
    this.currentFrame = Math.round(index);
    if (this.currentFrame < this.startFrame) {
      this.currentFrame = this.endFrame - (this.startFrame - this.currentFrame);
    }
    if (this.currentFrame > this.endFrame) {
      this.currentFrame = this.startFrame + (this.currentFrame - this.endFrame);
    }
  }

  addFrameIndex(index) {
    this.setFrameIndex(this.currentFrame + index);
  }

  _scaleImage(source, scaleFactor) {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const w = source.width * scaleFactor;
    const h = source.height * scaleFactor;
    canvas.width = w;
    canvas.height = h;
    ctx.drawImage(source, 0, 0, w, h);
    return canvas;
  }

  getScaleIndex() {
    return ENABLE_SCALE
      ? Math.floor(this.frameWidth / (this.nodeWidth - 100) / 2)
      : 0;
  }

  getFrameImage(index) {
    return this.frames[this.getScaleIndex()][index].image;
  }

  getAlternateFrameImage(key) {
    return this.alternateFrames[this.getScaleIndex()].find(
      (frame) => frame.index === key
    ).image;
  }

  drawFrame(image) {
    image && this.ctx.drawImage(image, 0, 0, this.node.width, this.node.height);
  }

  drawCurrentFrame(force = false) {
    if (!force && this.lastDrawFrame === this.currentFrame) {
      return;
    }
    this.drawFrame(this.getFrameImage(this.currentFrame));
    this.dispatchEvent({ type: EVENTS.DRAW_FRAME, frame: this.currentFrame });
    this.lastDrawFrame = this.currentFrame;
  }

  drawAlternateFrame(frameKey) {
    this.drawFrame(this.getAlternateFrameImage(frameKey));
  }

  dispose() {
    this.node.removeEventListener("mousemove", this._onMove);
    this.node.removeEventListener("mousedown", this._onDown);
    this.node.removeEventListener("mouseup", this._onUp);

    this.node.removeEventListener("touchmove", this._onMove);
    this.node.removeEventListener("touchstart", this._onDown);
    this.node.removeEventListener("touchend", this._onUp);
    this.node.removeEventListener("touchcancel", this._onUp);

    window.removeEventListener("resize", this._onResize);
  }
}
